import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import config from './config';

// Add Google Tag Manager noscript iframe if Google Tag is enabled
if (config.googleTag) {
  const gtmNoScript = document.createElement('noscript');
  const gtmIframe = document.createElement('iframe');
  gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${config.googleTagId}`;
  gtmIframe.height = "0";
  gtmIframe.width = "0";
  gtmIframe.style.display = "none";
  gtmIframe.style.visibility = "hidden";
  gtmNoScript.appendChild(gtmIframe);
  document.body.prepend(gtmNoScript);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

